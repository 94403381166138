.inner_div {
  /* padding: 2rem 4rem; */
  max-width: 754px;
  margin: 30px auto;
  background: #fff;
  box-shadow: 0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%),
    0 8px 10px -5px rgb(0 0 0 / 20%);
  border-radius: 12px;
  padding: 30px 40px;
}

.Project_title {
  font-size: 1.5rem !important;
  padding-bottom: 0px !important;
  margin-bottom: 10px !important;
  word-break: break-all !important;
  line-height: 30px !important;


}

.Project_desc {
  font-size: 1rem !important;
  padding-bottom: 0px !important;
  line-height: 22px !important;
}

.payment_summary {
  font-size: 1.5rem;
  padding-bottom: 0px;
  /* margin-top: 15px; */
  word-break: break-word;
}

.img_title {
  display: flex;
  gap: 4rem;
  margin-top: 1rem;
  display: flex !important;
  flex-direction: row !important;

}

.project_image {
  width: 8.25rem !important;
  min-width: 8.25rem !important;
}

.visa-image {
  height: 20px !important
}

.Klarna_span {
  cursor: pointer !important;
  margin-left: 5px !important;
  height: 21px !important;
  margin-top: 3px !important
}

.ideal_pay_span{
  cursor: pointer !important;
  /* margin-left: 5px !important; */
  height: 22px !important;
  margin-top: 3px !important
}

.septa_btn_span{
  cursor: pointer !important;
  margin-left: 4px !important;
  height: 24px !important;
  margin-top: 2px !important
}
.giro_pay_span{
  cursor: pointer !important;
  margin-left: 7px !important;
  height: 24px !important;
  margin-top: 2px !important
}
.sofort_span{
  cursor: pointer !important;
  margin-left: 7px !important;
  height: 24px !important;
  margin-top: 5px !important
}
.paypal_img{
  cursor: pointer !important;
  height:50px !important
}
.paypal_selected{
  display: flex !important;
  /* flex-direction: column-reverse !important; */
  gap:20px !important
}
.visa_selected{
  display: block !important;
  flex-direction:column !important;
  gap:20px !important
}
.paymentIcons{
  width: 60px;
  min-width: 60px;
  height: 40px;
  cursor: pointer;
}
.paymentIcons img{
  height: 100%;
}

@media (max-width: 768px) {
  .inner_div {
    padding: 30px 10px !important;
  }

  .payPalBtn {
    font-size: 14px !important;
    width: 100% !important;
  }

  .Gegenleistung-button {
    font-size: 16px !important;
    width: 100% !important;
  }

  .Project_title {
    font-size: 1rem !important;
    /* white-space: nowrap; */
    text-align: left !important;
    margin-bottom: 5px !important;
  }

  .Project_desc {
    font-size: 0.8rem !important;
    text-align: left !important;
    margin-bottom: 0px !important;
  }

  .payment_summary {
    font-size: 1rem !important;
    text-align: left !important;
  }

  .retrunBtn {
    font-size: 14px !important;
    text-align: left !important;
    margin-bottom: 0px !important;
  }

  .img_title {
    gap: 1rem;
    display: flex !important;
    flex-direction: column !important;
  }

  .project_image {
    width: 100% !important;
  }

  .visa-image {
    height: 20px !important
  }

  .paypal_img{
    cursor: pointer !important;
    height:30px !important
  }

  .paymentIcons{
    width: 32px;
    min-width: 32px;
    height: 25px;
    cursor: pointer;
  }

  /* .Klarna_span {
    cursor: pointer !important;
    margin-left: 0px !important;
    height: 16px !important;
    margin-top: 3px !important
  }
  
  .ideal_pay_span{
    cursor: pointer !important;
    margin-left: 0px !important;
    height: 16px !important;
    margin-top: 3px !important
  }
  
  .septa_btn_span{
    cursor: pointer !important;
    margin-left: 0px !important;
    height: 14px !important;
    margin-top: 2px !important
  }
  .giro_pay_span{
    cursor: pointer !important;
    margin-left: 0px !important;
    height: 14px !important;
    margin-top: 2px !important
  }
  .sofort_span{
    cursor: pointer !important;
    margin-left: 0px !important;
    height: 14px !important;
    margin-top: 5px !important
  } */
}